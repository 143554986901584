import {
  ColumnFilterParam,
  ColumnOrder,
  DatatableColumnV2,
  DatatableColumnV2Groups,
  DatatablePagination,
  DatatableSort,
  buildGroupsMap,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { STRINGS } from './products-v2.strings';
import { SortOrder } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import {
  Filter,
  FilterTypes,
} from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';
import {
  ServiceRequestInfoV3FilteringType,
  ServiceRequestInfoV3Operators,
} from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import { COLUMNS_GROUPS } from 'src/app/shared/models/datatable/v2/columns-groups.model';
import { FilterTypeOptionsV2 } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter-v2.model';

export const defaultSort: DatatableSort = {
  [STRINGS.columns.products.IGNORED.key]: {
    field: STRINGS.columns.products.IGNORED.filterKey,
    order: SortOrder.ASC,
  },
  [STRINGS.columns.products.ASSEMBLED.key]: {
    field: STRINGS.columns.products.ASSEMBLED.filterKey,
    order: SortOrder.ASC,
  },
  [STRINGS.columns.products.CATEGORY.key]: {
    field: STRINGS.columns.products.CATEGORY.filterKey,
    order: SortOrder.ASC,
  },
};

export const COLUMNS_ORDER: ColumnOrder = {
  [STRINGS.columns.products.SELECTION.key]: 1,
  [STRINGS.columns.products.NOTES.key]: 2,
  [STRINGS.columns.products.CATEGORY.key]: 3,
  [STRINGS.columns.products.XYZ_CATEGORY.key]: 4,
  [STRINGS.columns.products.NOVEL.key]: 5,
  [STRINGS.columns.products.CREATED_AT_REMOTE.key]: 6,
  [STRINGS.columns.products.NAME.key]: 7,
  [STRINGS.columns.products.ATTRIBUTES.key]: 8,
  [STRINGS.columns.products.SKU.key]: 9,
  [STRINGS.columns.products.EAN_CODE.key]: 10,
  [STRINGS.columns.products.ARTICLE_CODE.key]: 11,
  [STRINGS.columns.products.SERVICE_LEVEL.key]: 12,
  [STRINGS.columns.products.STOCK_TIME.key]: 13,
  [STRINGS.columns.products.FREE_STOCK.key]: 14,
  [STRINGS.columns.products.NUM_ON_ORDER.key]: 15,
  [STRINGS.columns.products.GLOBAL_STOCK_POSITION.key]: 16,
  [STRINGS.columns.products.EXPECTED_DEMAND.key]: 17,
  [STRINGS.columns.products.DEAD_STOCK.key]: 18,
  [STRINGS.columns.products.MANUAL_SERVICE_LEVEL.key]: 19,
  [STRINGS.columns.products.MINIMUM_STOCK.key]: 20,
  [STRINGS.columns.products.MAXIMUM_STOCK.key]: 21,
  [STRINGS.columns.products.SALES_PRICE.key]: 22,
  [STRINGS.columns.products.ACTIONS.key]: 23,
};

export const defaultColumnsV2: DatatableColumnV2Groups<DatatableColumnV2> = {
  [COLUMNS_GROUPS.FIXED_START.key]: {
    groupKey: COLUMNS_GROUPS.FIXED_START.key,
    groupName: COLUMNS_GROUPS.FIXED_START.name,
    columns: {
      [STRINGS.columns.products.SELECTION.key]: {
        name: STRINGS.columns.products.SELECTION.key,
        displayName: STRINGS.columns.products.SELECTION.name,
        checked: true,
        type: null,
        selectable: false,
        disabled: false,
      },
    },
  },
  [COLUMNS_GROUPS.PRODUCT.key]: {
    groupKey: COLUMNS_GROUPS.PRODUCT.key,
    groupName: COLUMNS_GROUPS.PRODUCT.name,
    columns: {
      [STRINGS.columns.products.NOTES.key]: {
        name: STRINGS.columns.products.NOTES.key,
        displayName: STRINGS.columns.products.NOTES.name,
        checked: true,
        type: null,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.products.CATEGORY.key]: {
        name: STRINGS.columns.products.CATEGORY.key,
        displayName: STRINGS.columns.products.CATEGORY.name,
        checked: true,
        type: FilterTypes.FILTER_LIST,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.products.XYZ_CATEGORY.key]: {
        name: STRINGS.columns.products.XYZ_CATEGORY.key,
        displayName: STRINGS.columns.products.XYZ_CATEGORY.name,
        checked: true,
        type: FilterTypes.FILTER_LIST,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.products.NOVEL.key]: {
        name: STRINGS.columns.products.NOVEL.key,
        displayName: STRINGS.columns.products.NOVEL.name,
        checked: true,
        type: FilterTypes.FILTER_BOOLEAN,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.products.CREATED_AT_REMOTE.key]: {
        name: STRINGS.columns.products.CREATED_AT_REMOTE.key,
        displayName: STRINGS.columns.products.CREATED_AT_REMOTE.name,
        checked: true,
        type: FilterTypes.FILTER_DATE,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.products.NAME.key]: {
        name: STRINGS.columns.products.NAME.key,
        displayName: STRINGS.columns.products.NAME.name,
        checked: true,
        type: FilterTypes.FILTER_TEXT,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.products.ATTRIBUTES.key]: {
        name: STRINGS.columns.products.ATTRIBUTES.key,
        displayName: STRINGS.columns.products.ATTRIBUTES.name,
        checked: true,
        type: FilterTypes.FILTER_LIST,
        selectable: false,
        disabled: false,
      },
      [STRINGS.columns.products.SKU.key]: {
        name: STRINGS.columns.products.SKU.key,
        displayName: STRINGS.columns.products.SKU.name,
        checked: true,
        type: FilterTypes.FILTER_TEXT,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.products.EAN_CODE.key]: {
        name: STRINGS.columns.products.EAN_CODE.key,
        displayName: STRINGS.columns.products.EAN_CODE.name,
        checked: true,
        type: FilterTypes.FILTER_TEXT,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.products.ARTICLE_CODE.key]: {
        name: STRINGS.columns.products.ARTICLE_CODE.key,
        displayName: STRINGS.columns.products.ARTICLE_CODE.name,
        checked: true,
        type: FilterTypes.FILTER_TEXT,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.products.SERVICE_LEVEL.key]: {
        name: STRINGS.columns.products.SERVICE_LEVEL.key,
        displayName: STRINGS.columns.products.SERVICE_LEVEL.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.products.STOCK_TIME.key]: {
        name: STRINGS.columns.products.STOCK_TIME.key,
        displayName: STRINGS.columns.products.STOCK_TIME.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.products.FREE_STOCK.key]: {
        name: STRINGS.columns.products.FREE_STOCK.key,
        displayName: STRINGS.columns.products.FREE_STOCK.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.products.NUM_ON_ORDER.key]: {
        name: STRINGS.columns.products.NUM_ON_ORDER.key,
        displayName: STRINGS.columns.products.NUM_ON_ORDER.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.products.GLOBAL_STOCK_POSITION.key]: {
        name: STRINGS.columns.products.GLOBAL_STOCK_POSITION.key,
        displayName: STRINGS.columns.products.GLOBAL_STOCK_POSITION.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.products.EXPECTED_DEMAND.key]: {
        name: STRINGS.columns.products.EXPECTED_DEMAND.key,
        displayName: STRINGS.columns.products.EXPECTED_DEMAND.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.products.DEAD_STOCK.key]: {
        name: STRINGS.columns.products.DEAD_STOCK.key,
        displayName: STRINGS.columns.products.DEAD_STOCK.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.products.MANUAL_SERVICE_LEVEL.key]: {
        name: STRINGS.columns.products.MANUAL_SERVICE_LEVEL.key,
        displayName: STRINGS.columns.products.MANUAL_SERVICE_LEVEL.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.products.MINIMUM_STOCK.key]: {
        name: STRINGS.columns.products.MINIMUM_STOCK.key,
        displayName: STRINGS.columns.products.MINIMUM_STOCK.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.products.MAXIMUM_STOCK.key]: {
        name: STRINGS.columns.products.MAXIMUM_STOCK.key,
        displayName: STRINGS.columns.products.MAXIMUM_STOCK.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.products.SALES_PRICE.key]: {
        name: STRINGS.columns.products.SALES_PRICE.key,
        displayName: STRINGS.columns.products.SALES_PRICE.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
    },
  },
  [COLUMNS_GROUPS.FIXED_END.key]: {
    groupKey: COLUMNS_GROUPS.FIXED_END.key,
    groupName: COLUMNS_GROUPS.FIXED_END.name,
    columns: {
      [STRINGS.columns.products.ACTIONS.key]: {
        name: STRINGS.columns.products.ACTIONS.key,
        displayName: STRINGS.columns.products.ACTIONS.name,
        checked: true,
        type: null,
        selectable: false,
        disabled: false,
      },
    },
  },
};

export const columnsGroupsMap: Map<string, string> =
  buildGroupsMap(defaultColumnsV2);

export const defaultFiltersV2: DatatableColumnV2Groups<Filter> = {
  [COLUMNS_GROUPS.PRODUCT.key]: {
    groupKey: COLUMNS_GROUPS.PRODUCT.key,
    groupName: COLUMNS_GROUPS.PRODUCT.name,
    columns: {
      [STRINGS.columns.products.CATEGORY.key]: {
        name: STRINGS.columns.products.CATEGORY.name,
        key: STRINGS.columns.products.CATEGORY.filterKey,
        uiModel: FilterTypes.FILTER_LIST,
        options: [
          {
            key: 'A',
            value: 0,
          },
          {
            key: 'B',
            value: 1,
          },
          {
            key: 'C',
            value: 2,
          },
        ],
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.OR,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.products.XYZ_CATEGORY.key]: {
        name: STRINGS.columns.products.XYZ_CATEGORY.name,
        key: STRINGS.columns.products.XYZ_CATEGORY.filterKey,
        uiModel: FilterTypes.FILTER_LIST,
        options: [
          {
            key: 'X',
            value: 0,
          },
          {
            key: 'Y',
            value: 1,
          },
          {
            key: 'Z',
            value: 2,
          },
        ],
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.OR,
          value: null,
        },
        disabled: false,
        sharedFilter: true,
      },
      [STRINGS.columns.products.NOVEL.key]: {
        name: STRINGS.columns.products.NOVEL.name,
        key: STRINGS.columns.products.NOVEL.filterKey,
        uiModel: FilterTypes.FILTER_BOOLEAN,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.BOOLEAN,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.products.NAME.key]: {
        name: STRINGS.columns.products.NAME.name,
        key: STRINGS.columns.products.NAME.filterKey,
        uiModel: FilterTypes.FILTER_TEXT,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.products.SKU.key]: {
        name: STRINGS.columns.products.SKU.name,
        key: STRINGS.columns.products.SKU.filterKey,
        uiModel: FilterTypes.FILTER_TEXT,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.products.EAN_CODE.key]: {
        name: STRINGS.columns.products.EAN_CODE.name,
        key: STRINGS.columns.products.EAN_CODE.filterKey,
        uiModel: FilterTypes.FILTER_TEXT,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.products.ARTICLE_CODE.key]: {
        name: STRINGS.columns.products.ARTICLE_CODE.name,
        key: STRINGS.columns.products.ARTICLE_CODE.filterKey,
        uiModel: FilterTypes.FILTER_TEXT,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.products.SERVICE_LEVEL.key]: {
        name: STRINGS.columns.products.SERVICE_LEVEL.name,
        key: STRINGS.columns.products.SERVICE_LEVEL.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.FLOAT,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: true,
      },
      [STRINGS.columns.products.STOCK_TIME.key]: {
        name: STRINGS.columns.products.STOCK_TIME.name,
        key: STRINGS.columns.products.STOCK_TIME.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.FLOAT,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: true,
      },
      [STRINGS.columns.products.FREE_STOCK.key]: {
        name: STRINGS.columns.products.FREE_STOCK.name,
        key: STRINGS.columns.products.FREE_STOCK.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.products.NUM_ON_ORDER.key]: {
        name: STRINGS.columns.products.NUM_ON_ORDER.name,
        key: STRINGS.columns.products.NUM_ON_ORDER.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: null,
          max: null,
          step: 1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: true,
      },
      [STRINGS.columns.products.GLOBAL_STOCK_POSITION.key]: {
        name: STRINGS.columns.products.GLOBAL_STOCK_POSITION.name,
        key: STRINGS.columns.products.GLOBAL_STOCK_POSITION.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: null,
          max: null,
          step: 1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: true,
      },
      [STRINGS.columns.products.EXPECTED_DEMAND.key]: {
        name: STRINGS.columns.products.EXPECTED_DEMAND.name,
        key: STRINGS.columns.products.EXPECTED_DEMAND.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.FLOAT,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: true,
      },
      [STRINGS.columns.products.DEAD_STOCK.key]: {
        name: STRINGS.columns.products.DEAD_STOCK.name,
        key: STRINGS.columns.products.DEAD_STOCK.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: true,
      },
      [STRINGS.columns.products.MANUAL_SERVICE_LEVEL.key]: {
        name: STRINGS.columns.products.MANUAL_SERVICE_LEVEL.name,
        key: STRINGS.columns.products.MANUAL_SERVICE_LEVEL.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.products.MINIMUM_STOCK.key]: {
        name: STRINGS.columns.products.MINIMUM_STOCK.name,
        key: STRINGS.columns.products.MINIMUM_STOCK.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.products.MAXIMUM_STOCK.key]: {
        name: STRINGS.columns.products.MAXIMUM_STOCK.name,
        key: STRINGS.columns.products.MAXIMUM_STOCK.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.products.SALES_PRICE.key]: {
        name: STRINGS.columns.products.SALES_PRICE.name,
        key: STRINGS.columns.products.SALES_PRICE.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: null,
          max: null,
          step: 0.01,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.FLOAT,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },

      [STRINGS.columns.products.IGNORED.key]: {
        name: STRINGS.columns.products.IGNORED.name,
        key: STRINGS.columns.products.IGNORED.filterKey,
        uiModel: FilterTypes.FILTER_BOOLEAN,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.BOOLEAN,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.products.ASSEMBLED.key]: {
        name: STRINGS.columns.products.ASSEMBLED.name,
        key: STRINGS.columns.products.ASSEMBLED.filterKey,
        uiModel: FilterTypes.FILTER_BOOLEAN,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.BOOLEAN,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.products.NOT_BEING_BOUGHT.key]: {
        name: STRINGS.columns.products.NOT_BEING_BOUGHT.name,
        key: STRINGS.columns.products.NOT_BEING_BOUGHT.filterKey,
        uiModel: FilterTypes.FILTER_BOOLEAN,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.BOOLEAN,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.products.PROMOTION_NAME.key]: {
        name: STRINGS.columns.products.PROMOTION_NAME.name,
        key: STRINGS.columns.products.PROMOTION_NAME.filterKey,
        uiModel: FilterTypes.FILTER_TEXT,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.products.PROMOTION_START_DATE.key]: {
        name: STRINGS.columns.products.PROMOTION_START_DATE.name,
        key: STRINGS.columns.products.PROMOTION_START_DATE.filterKey,
        uiModel: FilterTypes.FILTER_DATE,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.DATETIME,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.products.PROMOTION_END_DATE.key]: {
        name: STRINGS.columns.products.PROMOTION_END_DATE.name,
        key: STRINGS.columns.products.PROMOTION_END_DATE.filterKey,
        uiModel: FilterTypes.FILTER_DATE,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.DATETIME,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.products.CREATED_AT_REMOTE.key]: {
        name: STRINGS.columns.products.CREATED_AT_REMOTE.name,
        key: STRINGS.columns.products.CREATED_AT_REMOTE.filterKey,
        uiModel: FilterTypes.FILTER_DATE,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.DATETIME,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
    },
  },
  [COLUMNS_GROUPS.SUPPLIER_PRODUCT.key]: {
    groupKey: COLUMNS_GROUPS.SUPPLIER_PRODUCT.key,
    groupName: COLUMNS_GROUPS.SUPPLIER_PRODUCT.name,
    columns: {
      [STRINGS.columns.products.SUPPLIER_CONNECTION.key]: {
        name: STRINGS.columns.products.SUPPLIER_CONNECTION.name,
        key: STRINGS.columns.products.SUPPLIER_CONNECTION.filterKey,
        uiModel: FilterTypes.FILTER_NOT_EXISTS,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.NOT_EXISTS,
          operator: FilterTypeOptionsV2.NOT_EXISTS,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.products.SUPPLIER_PRODUCT_PRICE.key]: {
        name: STRINGS.columns.products.SUPPLIER_PRODUCT_PRICE.name,
        key: STRINGS.columns.products.SUPPLIER_PRODUCT_PRICE.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: null,
          max: null,
          step: 0.01,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.FLOAT,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
    },
  },
};

export const filtersGroupsMap: Map<string, string> =
  buildGroupsMap(defaultFiltersV2);

export const defaultPagination: DatatablePagination = {
  page: 0,
  size: 50,
  pageSizeOptions: [10, 15, 25, 50, 75, 100, 125, 150, 200],
};

export const ignoredFilter: ColumnFilterParam = {
  columnKey: 'IGNORED',
  optionSelected: null,
  subOperator: ServiceRequestInfoV3Operators.AND,
  values: {
    to: false,
  },
  applyAll: false,
};

export const assembledFilter: ColumnFilterParam = {
  columnKey: 'ASSEMBLED',
  optionSelected: null,
  subOperator: ServiceRequestInfoV3Operators.AND,
  values: {
    to: false,
  },
  applyAll: false,
};

export const sellingPriceFilter: ColumnFilterParam = {
  columnKey: 'SALES_PRICE',
  optionSelected: 'ranged',
  subOperator: ServiceRequestInfoV3Operators.AND,
  values: {
    from: null,
    to: 0,
  },
  applyAll: false,
};

export const purchasePriceFilter: ColumnFilterParam = {
  columnKey: 'SUPPLIER_PRODUCT_PRICE',
  optionSelected: 'ranged',
  subOperator: ServiceRequestInfoV3Operators.AND,
  values: {
    from: null,
    to: 0,
  },
  applyAll: false,
};

export const supplierFilter: ColumnFilterParam = {
  columnKey: 'SUPPLIER_CONNECTION',
  optionSelected: 'not_exists',
  subOperator: ServiceRequestInfoV3Operators.AND,
  values: null,
  applyAll: false,
};

export const sellingPriceFilterOr: ColumnFilterParam = {
  columnKey: 'SALES_PRICE',
  optionSelected: 'ranged',
  subOperator: ServiceRequestInfoV3Operators.OR,
  values: {
    from: null,
    to: 0,
  },
  applyAll: false,
};

export const purchasePriceFilterOr: ColumnFilterParam = {
  columnKey: 'SUPPLIER_PRODUCT_PRICE',
  optionSelected: 'ranged',
  subOperator: ServiceRequestInfoV3Operators.OR,
  values: {
    from: null,
    to: 0,
  },
  applyAll: false,
};

export const PRODUCT_FILTERS = {
  CONNECT_TO_SUPPLIER: [ignoredFilter, assembledFilter, supplierFilter],
  UPDATE_PURCHASE_PRICE: [ignoredFilter, assembledFilter, purchasePriceFilter],
  UPDATE_SELLING_PRICE: [ignoredFilter, assembledFilter, sellingPriceFilter],
};
