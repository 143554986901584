import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import { append, patch, removeItem } from '@ngxs/store/operators';
import { DateTime } from 'luxon';
import { catchError, concatMap, mergeMap, Observable, tap } from 'rxjs';
import {
  LoadFailed,
  SaveFailed,
  SaveSucceed,
  Saving,
} from 'src/app/core/actions/app.action';
import { LoadAccountSettings } from 'src/app/core/actions/settings.action';
import { AccountSettingsService } from 'src/app/core/api/account/v2/account-settings.service';
import {
  UpdateMultipleWebshopProducts,
  UpdateMultipleWebshopProductsProperties,
  UpdateWebshopProductProperties,
} from 'src/app/core/api/products/v3/model/products-v3.model';
import { ProductsV3Service } from 'src/app/core/api/products/v3/products-v3.service';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { NotificationCenterService } from 'src/app/core/services/notification-center.service';
import { AccountSettingsState } from 'src/app/core/states/account-settings.state';
import { AccountState } from 'src/app/core/states/account.state';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { AccountSettingsUpdate } from 'src/app/shared/models/account/account-settings.model';
import {
  ExportDataV2,
  ExportRequestDataV2,
} from 'src/app/shared/models/exports/exports-v2.model';
import {
  WebshopProduct,
  WebshopProducts,
} from 'src/app/shared/models/products/v3/products.model';
import { TableSelection } from 'src/app/shared/models/selection/selection.model';
import {
  createExportAnchorElement,
  createExportData,
  executeExport,
} from 'src/app/shared/utils/exports.utils';
import {
  AddFilterParam,
  AddSearchParam,
  ClearSelection,
  ColumnsSelected,
  DoNotPhaseOutProduct,
  HideColumn,
  LoadProductsV2,
  MasterToggleSelection,
  Paginate,
  PhaseOutProduct,
  IgnoreProduct,
  UnignoreProduct,
  RemoveAllFilters,
  ResetPagination,
  Sort,
  ToggleFilter,
  ToggleRowSelection,
  PhaseOutProducts,
  DoNotPhaseOutProducts,
  IgnoreProducts,
  UnignoreProducts,
  RemoveAddedToPromotionProductsFromSelection,
  InitializeProductsV2State,
  ExportProductsV2,
  AddFilterParamNoReload,
  RemoveSearchParam,
  ResetPaginationAndLoadData,
  ReloadDatatable,
  LoadProductsV2Count,
} from '../actions/products-v2.actions';
import {
  columnsGroupsMap,
  defaultColumnsV2,
  defaultFiltersV2,
  defaultPagination,
  defaultSort,
  filtersGroupsMap,
} from '../model/data-table.model';
import { SelectedWebshopProduct } from '../model/products-v2.model';
import { STRINGS } from '../model/products-v2.strings';
import {
  DatatableState,
  DatatableStateModel,
} from 'src/app/shared/components/design-system/data-table-v2/state/data-table.state';
import { PageableV2 } from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import {
  DatatableColumnV2,
  DatatableColumnV2Groups,
  DatatablePagination,
  DatatableParam,
  defaultDatatableStateModel,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import {
  SortOrder,
  Sorted,
  SortedOrder,
} from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { FilterTypesOptionsV2 } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter-v2.model';
import { WebshopSelected } from 'src/app/core/actions/webshop.action';
import { Filter } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';
import { DataPageableV2 } from 'src/app/shared/models/datatable/v2/pageable-v2.model';
import { ExportData } from 'src/app/shared/models/exports/exports.model';

export interface ProductsV2StateModel extends DatatableStateModel {
  products: WebshopProduct[];
  page: PageableV2;
  loading: boolean;
  selection: TableSelection<SelectedWebshopProduct>;
  loadingPagination: boolean;
}

@State<ProductsV2StateModel>({
  name: 'productsV2State',
  defaults: {
    ...defaultDatatableStateModel,
    pagination: defaultPagination,
    columnsGroups: defaultColumnsV2,
    filtersGroups: defaultFiltersV2,
    sortBy: defaultSort,
    search: [],
    products: [],
    page: { totalElements: 0 },
    loading: true,
    filterOpened: false,
    selection: {},
    loadingPagination: false,
  },
})
@Injectable()
export class ProductsV2State extends DatatableState {
  readonly notExportableColumns: string[] = [
    STRINGS.columns.products.SELECTION.key,
    STRINGS.columns.products.ATTRIBUTES.key,
    STRINGS.columns.products.ACTIONS.key,
  ];

  static filterDataByColumnKey(columnKey: string) {
    return createSelector([ProductsV2State], (state: ProductsV2StateModel) => {
      const groupKey = filtersGroupsMap.get(columnKey);

      if (!groupKey) {
        throw new Error(`Column group ${groupKey} does not exist`);
      }

      if (state.filtersGroups[groupKey].columns[columnKey] === undefined) {
        throw new Error(`Column ${columnKey} does not exist`);
      }

      const sortBy = new Map(Object.entries(state.sortBy));

      return {
        columnKey,
        filter: state.filtersGroups[groupKey].columns[columnKey],
        filtered:
          !!state.filtersGroups[groupKey].columns[columnKey].params.value,
        sorted: sortBy.has(
          state.filtersGroups[groupKey].columns[columnKey]?.key
        )
          ? (sortBy.get(state.filtersGroups[groupKey].columns[columnKey].key)
              .order as SortedOrder)
          : null,
      };
    });
  }

  @Selector()
  static selection(
    state: ProductsV2StateModel
  ): TableSelection<SelectedWebshopProduct> {
    return state.selection;
  }

  @Selector()
  static products(state: ProductsV2StateModel): WebshopProduct[] {
    return state.products;
  }

  constructor(
    private store: Store,
    private productsV3Service: ProductsV3Service,
    private accountSettingsService: AccountSettingsService,
    private notificationCenter: NotificationCenterService
  ) {
    super();
  }

  @Action(InitializeProductsV2State, { cancelUncompleted: true })
  initialize(ctx: StateContext<ProductsV2StateModel>) {
    const tableSettings = this.store.selectSnapshot(
      AccountSettingsState.productsEditorTableSettings
    );

    const columns = this.initializeColumnsFromPersistence(
      ctx.getState().columnsGroups,
      tableSettings.excludedColumns
    );

    ctx.setState(
      patch<ProductsV2StateModel>({
        columnsGroups: columns,
        pagination: patch<DatatablePagination>({
          size: tableSettings.pageSize,
        }),
      })
    );

    return ctx.dispatch([new LoadProductsV2(), new LoadProductsV2Count()]);
  }

  @Action(LoadProductsV2, { cancelUncompleted: true })
  loadProducts(ctx: StateContext<ProductsV2StateModel>) {
    this.currentFilters = this.buildRequestInfoFilters(ctx.getState());

    ctx.setState(
      patch({
        loading: true,
      })
    );

    return this._fetchProducts(ctx).pipe(
      tap((products: WebshopProducts) => {
        ctx.setState(
          patch({
            products: products.data,
            loading: false,
          })
        );
      })
    );
  }

  @Action(LoadProductsV2Count, { cancelUncompleted: true })
  loadProductsCount(ctx: StateContext<ProductsV2StateModel>) {
    ctx.setState(
      patch({
        loadingPagination: true,
      })
    );

    return this._fetchProductsCount(ctx).pipe(
      tap((pagination: DataPageableV2) => {
        ctx.setState(
          patch({
            page: { totalElements: pagination.totalElements },
            loadingPagination: false,
          })
        );
      })
    );
  }

  @Action(ReloadDatatable)
  reloadDatatable(ctx: StateContext<ProductsV2StateModel>) {
    return ctx.dispatch([new LoadProductsV2(), new LoadProductsV2Count()]);
  }

  @Action(ReloadDatatable)
  handleRefresh(ctx: StateContext<ProductsV2StateModel>) {
    return this.disableRefresh(ctx);
  }

  @Action(Paginate, { cancelUncompleted: true })
  paginate(ctx: StateContext<ProductsV2StateModel>, payload: Paginate) {
    const state = ctx.getState();

    const didPageSizeChange =
      ctx.getState().pagination.size !== payload.pagination.pageSize;

    if (didPageSizeChange) {
      return this._saveTableSizePersistance(
        ctx,
        payload.pagination.pageSize
      ).pipe(
        tap(() => {
          ctx.patchState({
            pagination: {
              ...state.pagination,
              page: payload.pagination.pageIndex,
              size: payload.pagination.pageSize,
            },
          });
        }),
        mergeMap(() =>
          ctx.dispatch([new LoadProductsV2(), new LoadProductsV2Count()])
        ),
        mergeMap(() =>
          ctx.dispatch(
            new LoadAccountSettings(
              this.store.selectSnapshot(AccountState.userUuid)
            )
          )
        )
      );
    }

    ctx.patchState({
      pagination: {
        ...state.pagination,
        page: payload.pagination.pageIndex,
        size: payload.pagination.pageSize,
      },
    });

    return ctx.dispatch([new LoadProductsV2(), new LoadProductsV2Count()]);
  }

  @Action(PhaseOutProduct, { cancelUncompleted: true })
  phaseOut(
    ctx: StateContext<ProductsV2StateModel>,
    { payload }: PhaseOutProduct
  ) {
    const requestInfo: Partial<UpdateWebshopProductProperties> = {
      notBeingBought: true,
      resumingPurchase: {
        null: 'NULL_VALUE',
      },
    };

    if (payload.resumingPurchase) {
      requestInfo.resumingPurchase = {
        value: DateTime.fromJSDate(payload.resumingPurchase).toFormat(
          'yyyy-MM-dd'
        ),
      };
    }

    return ctx.dispatch(new Saving()).pipe(
      mergeMap(() =>
        this._updateProduct(ctx, payload.productUuid, requestInfo)
      ),
      tap(() => this._removeFromSelection(ctx, payload.productUuid)),
      tap(() => payload.dialogRef.close()),
      concatMap(() =>
        ctx.dispatch([
          new SaveSucceed(),
          new LoadProductsV2(),
          new LoadProductsV2Count(),
        ])
      )
    );
  }

  @Action(PhaseOutProducts, { cancelUncompleted: true })
  phaseOutMultiple(
    ctx: StateContext<ProductsV2StateModel>,
    { payload }: PhaseOutProducts
  ) {
    const toPhaseOutProducts = Object.values(ctx.getState().selection).filter(
      (product: SelectedWebshopProduct) =>
        !product.notBeingBought && !product.ignored && !product.assembled
    );

    const requestInfo: UpdateMultipleWebshopProductsProperties =
      this._buildMultiplePhaseOutProductsRequestInfo(
        toPhaseOutProducts,
        payload.resumingPurchase
      );

    return ctx.dispatch(new Saving()).pipe(
      mergeMap(() => this._updateMultipleProducts(ctx, requestInfo)),
      tap(() => {
        this._removeMultipleFromSelection(
          ctx,
          toPhaseOutProducts.map(
            (selectedProduct: SelectedWebshopProduct) => selectedProduct.uuid
          )
        );
      }),
      tap(() => payload.dialogRef.close()),
      concatMap(() =>
        ctx.dispatch([
          new SaveSucceed(),
          new LoadProductsV2(),
          new LoadProductsV2Count(),
        ])
      )
    );
  }

  @Action(DoNotPhaseOutProduct, { cancelUncompleted: true })
  doNotPhaseOut(
    ctx: StateContext<ProductsV2StateModel>,
    payload: DoNotPhaseOutProduct
  ) {
    return ctx.dispatch(new Saving()).pipe(
      mergeMap(() =>
        this._updateProduct(ctx, payload.productUuid, {
          notBeingBought: false,
          resumingPurchase: {
            null: 'NULL_VALUE',
          },
        })
      ),
      tap(() => this._removeFromSelection(ctx, payload.productUuid)),
      concatMap(() =>
        ctx.dispatch([
          new SaveSucceed(),
          new LoadProductsV2(),
          new LoadProductsV2Count(),
        ])
      )
    );
  }

  @Action(DoNotPhaseOutProducts, { cancelUncompleted: true })
  doNotPhaseOutMultiple(ctx: StateContext<ProductsV2StateModel>) {
    const toNotPhaseOut = Object.values(ctx.getState().selection).filter(
      (product: SelectedWebshopProduct) =>
        product.notBeingBought && !product.ignored && !product.assembled
    );

    const requestInfo: UpdateMultipleWebshopProductsProperties =
      this._buildMultipleDoNotPhaseOutProductsRequestInfo(toNotPhaseOut);

    return ctx.dispatch(new Saving()).pipe(
      mergeMap(() => this._updateMultipleProducts(ctx, requestInfo)),
      tap(() => {
        this._removeMultipleFromSelection(
          ctx,
          toNotPhaseOut.map(
            (selectedProduct: SelectedWebshopProduct) => selectedProduct.uuid
          )
        );
      }),
      concatMap(() =>
        ctx.dispatch([
          new SaveSucceed(),
          new LoadProductsV2(),
          new LoadProductsV2Count(),
        ])
      )
    );
  }

  @Action(IgnoreProduct, { cancelUncompleted: true })
  ignore(ctx: StateContext<ProductsV2StateModel>, payload: IgnoreProduct) {
    return ctx.dispatch(new Saving()).pipe(
      mergeMap(() =>
        this._updateProduct(ctx, payload.productUuid, {
          ignored: true,
        })
      ),
      tap(() => this._removeFromSelection(ctx, payload.productUuid)),
      concatMap(() =>
        ctx.dispatch([
          new SaveSucceed(),
          new LoadProductsV2(),
          new LoadProductsV2Count(),
        ])
      )
    );
  }

  @Action(IgnoreProducts, { cancelUncompleted: true })
  ignoreProducts(ctx: StateContext<ProductsV2StateModel>) {
    const toIgnore = Object.values(ctx.getState().selection).filter(
      (product: SelectedWebshopProduct) => !product.ignored
    );

    const requestInfo: UpdateMultipleWebshopProductsProperties =
      this._buildMultipleIgnoreProductsRequestInfo(toIgnore);

    return ctx.dispatch(new Saving()).pipe(
      mergeMap(() => this._updateMultipleProducts(ctx, requestInfo)),
      tap(() => {
        this._removeMultipleFromSelection(
          ctx,
          toIgnore.map(
            (selectedProduct: SelectedWebshopProduct) => selectedProduct.uuid
          )
        );
      }),
      concatMap(() =>
        ctx.dispatch([
          new SaveSucceed(),
          new LoadProductsV2(),
          new LoadProductsV2Count(),
        ])
      )
    );
  }

  @Action(UnignoreProducts, { cancelUncompleted: true })
  unignoreProducts(ctx: StateContext<ProductsV2StateModel>) {
    const toIgnore = Object.values(ctx.getState().selection).filter(
      (product: SelectedWebshopProduct) => product.ignored
    );

    const requestInfo: UpdateMultipleWebshopProductsProperties =
      this._buildMultipleUnignoreProductsRequestInfo(toIgnore);

    return ctx.dispatch(new Saving()).pipe(
      mergeMap(() => this._updateMultipleProducts(ctx, requestInfo)),
      tap(() => {
        this._removeMultipleFromSelection(
          ctx,
          toIgnore.map(
            (selectedProduct: SelectedWebshopProduct) => selectedProduct.uuid
          )
        );
      }),
      concatMap(() =>
        ctx.dispatch([
          new SaveSucceed(),
          new LoadProductsV2(),
          new LoadProductsV2Count(),
        ])
      )
    );
  }

  @Action(UnignoreProduct, { cancelUncompleted: true })
  unignore(ctx: StateContext<ProductsV2StateModel>, payload: UnignoreProduct) {
    return ctx.dispatch(new Saving()).pipe(
      mergeMap(() =>
        this._updateProduct(ctx, payload.productUuid, {
          ignored: false,
        })
      ),
      tap(() => this._removeFromSelection(ctx, payload.productUuid)),
      concatMap(() =>
        ctx.dispatch([
          new SaveSucceed(),
          new LoadProductsV2(),
          new LoadProductsV2Count(),
        ])
      )
    );
  }

  @Action(AddFilterParam, { cancelUncompleted: true })
  addFilterParam(
    ctx: StateContext<ProductsV2StateModel>,
    payload: AddFilterParam
  ) {
    const groupKey = filtersGroupsMap.get(payload.param.columnKey);

    if (!groupKey) return;

    this.addGroupParam(ctx, groupKey, payload.param.columnKey, {
      operator: payload.param.optionSelected as FilterTypesOptionsV2,
      subOperator: payload.param.subOperator,
      value: payload.param.values,
    });

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(AddSearchParam, { cancelUncompleted: true })
  addSearchParam(
    ctx: StateContext<ProductsV2StateModel>,
    payload: AddSearchParam
  ) {
    ctx.setState(
      patch<ProductsV2StateModel>({ search: append<string>([payload.param]) })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(RemoveSearchParam, { cancelUncompleted: true })
  removeSearchParam(
    ctx: StateContext<ProductsV2StateModel>,
    payload: RemoveSearchParam
  ) {
    ctx.setState(
      patch<ProductsV2StateModel>({
        search: removeItem<string>(
          searchParam => searchParam === payload.param
        ),
      })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(Sort, { cancelUncompleted: true })
  sortTable(ctx: StateContext<ProductsV2StateModel>, payload: Sort) {
    return this.sort(ctx, payload.sort);
  }

  @Action(ColumnsSelected, { cancelUncompleted: true })
  columnsSelected(
    ctx: StateContext<ProductsV2StateModel>,
    payload: ColumnsSelected
  ) {
    return this._saveTableColumnsPersistance(
      ctx,
      payload.selection.columnsGroups
    ).pipe(
      tap(() => {
        ctx.setState(
          patch({
            columnsGroups: {
              ...ctx.getState().columnsGroups,
              ...payload.selection.columnsGroups,
            },
          })
        );
      }),
      mergeMap(() =>
        ctx.dispatch(
          new LoadAccountSettings(
            this.store.selectSnapshot(AccountState.userUuid)
          )
        )
      )
    );
  }

  @Action(HideColumn, { cancelUncompleted: true })
  hideColumn(ctx: StateContext<ProductsV2StateModel>, payload: HideColumn) {
    const groupKey = columnsGroupsMap.get(payload.columnKey);

    if (!groupKey) return;

    const newColumnSelection = this.hideGroupColumnFromColumnKey(
      ctx.getState().columnsGroups,
      groupKey,
      payload.columnKey
    );

    return this._saveTableColumnsPersistance(ctx, newColumnSelection).pipe(
      tap(() => {
        ctx.patchState({
          columnsGroups: newColumnSelection,
        });
      }),
      mergeMap(() =>
        ctx.dispatch(
          new LoadAccountSettings(
            this.store.selectSnapshot(AccountState.userUuid)
          )
        )
      )
    );
  }

  @Action(RemoveAllFilters, { cancelUncompleted: true })
  removeAllFilters(ctx: StateContext<ProductsV2StateModel>) {
    this._removeAllFilters(ctx);

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(WebshopSelected)
  removeAllFiltersOnWebshopChange(ctx: StateContext<ProductsV2StateModel>) {
    this._removeAllFilters(ctx);
  }

  @Action(ToggleFilter, { cancelUncompleted: true })
  toggleFilter(ctx: StateContext<ProductsV2StateModel>) {
    ctx.patchState({
      filterOpened: !ctx.getState().filterOpened,
    });
  }

  @Action(ResetPagination)
  resetPagination(
    ctx: StateContext<ProductsV2StateModel>,
    payload: ResetPagination
  ) {
    ctx.patchState({
      pagination: {
        ...ctx.getState().pagination,
        page: payload.page,
      },
    });
  }

  @Action(ToggleRowSelection, { cancelUncompleted: true })
  toggleRowSelection(
    ctx: StateContext<ProductsV2StateModel>,
    payload: ToggleRowSelection
  ) {
    const selectedCopy = { ...ctx.getState().selection };

    if (!payload.isSelected) {
      delete selectedCopy[payload.rowKey];

      ctx.patchState({
        selection: selectedCopy,
      });

      return;
    }

    ctx.patchState({
      selection: {
        ...ctx.getState().selection,
        [payload.rowKey]: {
          uuid: payload.rowKey,
          id: Number(payload.product.id),
          assembled: payload.product.assembled,
          ignored: payload.product.ignored,
          notBeingBought: payload.product.notBeingBought,
        },
      },
    });
  }

  @Action(MasterToggleSelection, { cancelUncompleted: true })
  masterToggleSelection(ctx: StateContext<ProductsV2StateModel>) {
    const lines = ctx.getState().products;

    const existingSelection = ctx.getState().selection;

    const linesUuid = lines.map(line => line.uuid);

    const selectedUids = new Set(Object.keys(existingSelection));

    const allSelected = linesUuid.every(line => selectedUids.has(line));

    const selectionCopy = new Map(Object.entries(existingSelection));

    if (allSelected) {
      lines.forEach(line => {
        selectionCopy.delete(line.uuid);
      });
    } else {
      lines.forEach(line => {
        selectionCopy.set(line.uuid, {
          uuid: line.uuid,
          id: Number(line.id),
          assembled: line.assembled,
          ignored: line.ignored,
          notBeingBought: line.notBeingBought,
        });
      });
    }

    ctx.patchState({
      selection: {
        ...Object.fromEntries(selectionCopy.entries()),
      },
    });
  }

  @Action(ClearSelection, { cancelUncompleted: true })
  clearSelection(ctx: StateContext<ProductsV2StateModel>) {
    ctx.patchState({
      selection: {},
    });
  }

  @Action(RemoveAddedToPromotionProductsFromSelection, {
    cancelUncompleted: true,
  })
  removeAddedToPromotionProductsFromSelection(
    ctx: StateContext<ProductsV2StateModel>
  ) {
    const toRemoveFromSelection = Object.values(ctx.getState().selection)
      .filter(
        (product: SelectedWebshopProduct) =>
          !product.assembled && !product.ignored
      )
      .map((product: SelectedWebshopProduct) => product.uuid);

    return this._removeMultipleFromSelection(ctx, toRemoveFromSelection);
  }

  @Action(ExportProductsV2, { cancelUncompleted: true })
  exportProducts(
    ctx: StateContext<ProductsV2StateModel>,
    payload: ExportProductsV2
  ) {
    this.notificationCenter.showToast(
      MESSAGES.notifications.exports.exportProductsDispatched
    );

    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const exportRequestInfo: ExportRequestDataV2 = this._buildExportData(
      ctx,
      payload.exportData
    );

    return this.productsV3Service
      .exportProducts(webshopUuid, exportRequestInfo)
      .pipe(
        catchError(e => {
          this.notificationCenter.showToastError(
            MESSAGES.notifications.exports.exportProductsFailed
          );
          throw new Error(e.message || e);
        })
      )
      .subscribe(response => {
        this.notificationCenter.showToastSuccess(
          MESSAGES.notifications.exports.exportProductsSuccessful
        );

        const exportData: ExportData = createExportData(response);

        executeExport(exportData);
      });
  }

  @Action(AddFilterParamNoReload, { cancelUncompleted: true })
  addFilterParamNoReload(
    ctx: StateContext<ProductsV2StateModel>,
    payload: AddFilterParamNoReload
  ) {
    payload.param.forEach(element => {
      const groupKey = filtersGroupsMap.get(element.columnKey);

      if (!groupKey) return;

      this.addGroupParam(ctx, groupKey, element.columnKey, {
        operator: element.optionSelected as FilterTypesOptionsV2,
        subOperator: element.subOperator,
        value: element.values,
      });
    });

    if (payload.reload) {
      return ctx.dispatch(new ResetPaginationAndLoadData());
    }
  }

  @Action(ResetPaginationAndLoadData, { cancelUncompleted: true })
  resetPaginationAndLoadData(ctx: StateContext<ProductsV2StateModel>) {
    return ctx.dispatch([
      new ResetPagination(),
      new LoadProductsV2(),
      new LoadProductsV2Count(),
    ]);
  }

  override addGroupParam(
    ctx: StateContext<ProductsV2StateModel>,
    groupKey: string,
    columnKey: string,
    param: DatatableParam
  ): void {
    ctx.setState(
      patch<ProductsV2StateModel>({
        filtersGroups: patch({
          [groupKey]: patch({
            columns: patch({
              [columnKey]: patch({
                params: patch<DatatableParam>({
                  ...param,
                  operator:
                    param.operator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .operator,
                  subOperator:
                    param.subOperator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .subOperator,
                  value:
                    columnKey ===
                      STRINGS.columns.products.SUPPLIER_CONNECTION.key &&
                    !!param.operator
                      ? { to: 'WEBSHOP_PRODUCT.UUID' }
                      : param.value,
                }),
              }),
            }),
          }),
        }),
      })
    );
  }

  sort(ctx: StateContext<ProductsV2StateModel>, sort: Sorted): void {
    ctx.patchState({
      sortBy: {
        [STRINGS.columns.products.IGNORED.key]: {
          field: STRINGS.columns.products.IGNORED.filterKey,
          order: SortOrder.ASC,
        },
        [STRINGS.columns.products.ASSEMBLED.key]: {
          field: STRINGS.columns.products.ASSEMBLED.filterKey,
          order: SortOrder.ASC,
        },
        [sort.key]: {
          field: sort.key,
          order: sort.order,
        },
      },
    });

    ctx.dispatch([new LoadProductsV2(), new LoadProductsV2Count()]);
  }

  private _removeAllFilters(ctx: StateContext<ProductsV2StateModel>) {
    ctx.patchState({
      filtersGroups: defaultFiltersV2,
      search: [],
      sortBy: defaultSort,
    });
  }

  private _fetchProducts(
    ctx: StateContext<ProductsV2StateModel>
  ): Observable<WebshopProducts | void> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this.productsV3Service
      .findAll(webshopUuid, this.currentFilters)
      .pipe(
        catchError(e => {
          ctx.setState(
            patch({
              products: [],
            })
          );

          ctx.dispatch(new LoadFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _fetchProductsCount(
    ctx: StateContext<ProductsV2StateModel>
  ): Observable<DataPageableV2> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this.productsV3Service
      .findAllCount(webshopUuid, this.currentFilters)
      .pipe(
        catchError(e => {
          ctx.setState(
            patch({
              page: { totalElements: 0 },
              loadingPagination: false,
            })
          );

          ctx.dispatch(new LoadFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _updateProduct(
    ctx: StateContext<ProductsV2StateModel>,
    webshopProductUuid: string,
    requestInfo: Partial<UpdateWebshopProductProperties>
  ): Observable<WebshopProducts | void> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this.productsV3Service
      .update(webshopUuid, webshopProductUuid, requestInfo)
      .pipe(
        catchError(e => {
          ctx.patchState({
            loading: false,
          });

          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _updateMultipleProducts(
    ctx: StateContext<ProductsV2StateModel>,
    requestInfo: UpdateMultipleWebshopProductsProperties
  ): Observable<WebshopProducts | void> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this.productsV3Service.multipleUpdate(webshopUuid, requestInfo).pipe(
      catchError(e => {
        ctx.patchState({
          loading: false,
        });

        ctx.dispatch(new SaveFailed());

        throw new Error(e.message || e);
      })
    );
  }

  private _buildMultiplePhaseOutProductsRequestInfo(
    selectedProducts: SelectedWebshopProduct[],
    resumingPurchase: Date | null
  ): UpdateMultipleWebshopProductsProperties {
    const toPhaseOutProducts: UpdateMultipleWebshopProducts[] =
      selectedProducts.map((product: SelectedWebshopProduct) => {
        const productInfo: UpdateMultipleWebshopProducts = {
          uuid: product.uuid,
          notBeingBought: true,
          resumingPurchase: {
            null: 'NULL_VALUE',
          },
        };

        if (resumingPurchase) {
          productInfo.resumingPurchase = {
            value: DateTime.fromJSDate(resumingPurchase).toFormat('yyyy-MM-dd'),
          };
        }

        return productInfo;
      });

    return {
      webshopProducts: toPhaseOutProducts,
    };
  }

  private _buildMultipleDoNotPhaseOutProductsRequestInfo(
    selectedProducts: SelectedWebshopProduct[]
  ): UpdateMultipleWebshopProductsProperties {
    const webshopProducts: UpdateMultipleWebshopProducts[] =
      selectedProducts.map((product: SelectedWebshopProduct) => {
        return {
          uuid: product.uuid,
          notBeingBought: false,
          resumingPurchase: {
            null: 'NULL_VALUE',
          },
        };
      });

    return {
      webshopProducts,
    };
  }

  private _buildMultipleIgnoreProductsRequestInfo(
    selectedProducts: SelectedWebshopProduct[]
  ): UpdateMultipleWebshopProductsProperties {
    const webshopProducts: UpdateMultipleWebshopProducts[] =
      selectedProducts.map((product: SelectedWebshopProduct) => {
        return {
          uuid: product.uuid,
          ignored: true,
        };
      });

    return {
      webshopProducts,
    };
  }

  private _buildMultipleUnignoreProductsRequestInfo(
    selectedProducts: SelectedWebshopProduct[]
  ): UpdateMultipleWebshopProductsProperties {
    const webshopProducts: UpdateMultipleWebshopProducts[] =
      selectedProducts.map((product: SelectedWebshopProduct) => {
        return {
          uuid: product.uuid,
          ignored: false,
        };
      });

    return {
      webshopProducts,
    };
  }

  private _removeMultipleFromSelection(
    ctx: StateContext<ProductsV2StateModel>,
    keys: string[]
  ): void {
    const selection = { ...ctx.getState().selection };

    keys.forEach((key: string) => {
      delete selection[key];
    });

    ctx.patchState({
      selection,
    });
  }

  private _removeFromSelection(
    ctx: StateContext<ProductsV2StateModel>,
    key: string
  ): void {
    const selection = { ...ctx.getState().selection };

    delete selection[key];

    ctx.patchState({
      selection,
    });
  }

  private _saveTableColumnsPersistance(
    ctx: StateContext<ProductsV2StateModel>,
    columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>
  ): Observable<any> {
    const requestInfo: AccountSettingsUpdate =
      this._buildSaveColumnPersistanceRequestInfo(columnsGroups);

    return this._updateAccountSettings(ctx, requestInfo);
  }

  private _buildSaveColumnPersistanceRequestInfo(
    columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>
  ): AccountSettingsUpdate {
    const excludedColumns: string[] = this.buildExcludedColumns(columnsGroups);

    return {
      productsEditorTableExcludedColumns: excludedColumns,
      overrideProductsEditorTableExcludedColumns: true,
    };
  }

  private _saveTableSizePersistance(
    ctx: StateContext<ProductsV2StateModel>,
    newPageSize: number
  ): Observable<any> {
    const requestInfo: AccountSettingsUpdate =
      this._buildSaveTableSizePersistanceRequestInfo(newPageSize);

    return this._updateAccountSettings(ctx, requestInfo);
  }

  private _buildSaveTableSizePersistanceRequestInfo(
    newPageSize: number
  ): AccountSettingsUpdate {
    return {
      productsEditorTablePageSize: newPageSize,
    };
  }

  private _updateAccountSettings(
    ctx: StateContext<ProductsV2StateModel>,
    requestInfo: AccountSettingsUpdate
  ): Observable<any> {
    const userUuid = this.store.selectSnapshot(AccountState.userUuid);

    return this.accountSettingsService
      .updateSettings(userUuid, requestInfo)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _buildExportData(
    ctx: StateContext<ProductsV2StateModel>,
    exportData: ExportDataV2
  ): ExportRequestDataV2 {
    const filtersWithoutGroups: Filter[] = Object.values(
      ctx.getState().filtersGroups
    )
      .map(group => Object.values(group.columns))
      .flat();

    const columnsWithoutGroups: DatatableColumnV2[] = Object.values(
      ctx.getState().columnsGroups
    )
      .map(group => Object.values(group.columns))
      .flat();

    const visibleColumns: string[] = columnsWithoutGroups
      .filter(
        (column: DatatableColumnV2) =>
          column.checked && !this.notExportableColumns.includes(column.name)
      )
      .map((column: DatatableColumnV2) => column.name);

    return {
      visibleColumns,
      fileName: exportData.fileName,
      fileType: exportData.fileType,
      queryData: {
        filters: this.buildFiltersV2(filtersWithoutGroups),
        sort_by: this.buildSortBy(ctx.getState().sortBy),
      },
      include: exportData.onlySelected
        ? [
            ...Object.values(ctx.getState().selection).map(
              (selected: SelectedWebshopProduct) => selected.uuid
            ),
          ]
        : [],
    };
  }
}
